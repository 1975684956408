.Home {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	text-align: center;
	direction: rtl;
	font-size: 2.6vh;
	font-family: "Rubik";
    z-index: 9999;
}

.appLogo {
	pointer-events: none;
    z-index: 9999;
}

.appLink {
	color: #61dafb;
}

.landingSection {
	width: 100%;
	height: 85vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #7c3aed;
    z-index: inherit;
}

.downloadBadgesDiv {
    display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	direction: ltr;
    padding-top: 2vh;
    z-index: inherit;
}

.appStoreBadge,
.googlePlayBadge {
	width: 22vh;
	margin: 10px;
	cursor: pointer;
}

.landingSectionDivider {
	overflow: hidden;
	position: relative;
}
.landingSectionDivider::before {
	content: "";
	font-family: "shape divider from ShapeDividers.com";
	position: absolute;
	bottom: -1px;
	left: -1px;
	right: -1px;
	top: -1px;
	z-index: 3;
	pointer-events: none;
	background-repeat: no-repeat;
	background-size: 100% 50px;
	background-position: 50% 0%;
	background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%237c3aed"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%237c3aed"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%237c3aed"/></svg>');
}

.aboutSection, .contactSection {
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 7vh;
	background-color: #f5f3ff;
    z-index: inherit;
    position: relative;
}

.aboutSectionContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10vmax;
    z-index: inherit;
}

.contactSectionContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
    z-index: inherit;
}

.aboutTitle, .contactTitle {
	text-align: right;
}

.aboutText, .contactText {
	text-align: right;
}

.aboutImage, .contactImage {
    width: 26%;
    aspect-ratio: 1;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 52px;
}

@media screen and (orientation: portrait) {
	.appLogo {
		width: 90%;
	}

	.downloadBadgesDiv {
		flex-direction: column;
	}
    
    .aboutSection, .contactSection {
        padding-left: 10%;
        padding-right: 10%;
    }

	.aboutSectionContent {
        flex-direction: column;
        justify-content: flex-start;
	}
    
    .contactSectionContent {
        flex-direction: column-reverse;
        justify-content: flex-start;
    }
    
    .aboutImage, .contactImage {
        width: 70%;
    }
    
    .contactImage {
        padding-top: 26px;
    }
}
@media screen and (orientation: landscape) {
    .appLogo {
        width: 26%;
	}
    
	.downloadBadgesDiv {
        flex-direction: row;
	}
    
    .aboutSection, .contactSection {
        padding-left: 15%;
        padding-right: 15%;
    }
    
	.aboutSectionContent {
        flex-direction: row;
        justify-content: space-between;
	}
    
    .contactSectionContent {
        flex-direction: row;
        justify-content: space-between;
    }
    
    .aboutImage, .contactImage {
        width: 26%;
    }
    
    .contactImage {
        padding-top: 52px;
    }
}

@media (min-width: 768px) {
	.landingSectionDivider::before {
		background-size: 100% 90px;
		background-position: 50% 0%;
	}
}

@media (min-width: 1025px) {
	.landingSectionDivider::before {
		bottom: -0.1vw;
		left: -0.1vw;
		right: -0.1vw;
		top: -0.1vw;
		background-size: 100% 90px;
		background-position: 50% 0%;
	}
}
@media (min-width: 2100px) {
	.landingSectionDivider::before {
		background-size: 100% calc(2vw + 90px);
	}
}
