@font-face {
	font-family: "Rubik";
	src: url("./assets/fonts/Rubik-Regular.woff") format("woff"),
		/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ url("./assets/fonts/Rubik-Regular.ttf")
			format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

html,
body,
#root {
	min-height: 100%;
}

.App {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	text-align: center;
	direction: rtl;
	font-size: 2.6vh;
	font-family: "Rubik";
}

.copyrights {
    direction: ltr;
    font-size: 15px;
	background-color: #f5f3ff;
    padding-bottom: 20px;
    position: relative;
}